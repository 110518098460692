<script>
import PaymentStripeSelectedRewardDisplay from "./PaymentStripeSelectedRewardDisplay.vue";
// import Swiper core and required modules
import {A11y, Navigation, Pagination, Scrollbar} from 'swiper/modules';


// Import Swiper Vue.js components
import {Swiper, SwiperSlide, useSwiper} from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import {loadStripe} from '@stripe/stripe-js';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const paymentReturn = urlParams.get('payment') === 'return';
const customerReturn = urlParams.get('customer_return');

const swiper = useSwiper();
const stripe = await loadStripe(window.x2_stripe_key);


export default {
  name: 'PaymentStripe',
  components: {
    PaymentStripeSelectedRewardDisplay,
    Swiper,
    SwiperSlide
  },
  setup() {
    const onSwiper = (swiper) => {
    };
    const onSlideChange = () => {
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  data() {
    return {
      selectedPaymentType: '',
      paymentTypes: window.moduleStripe.payment_types,
      donationsHeadline: '',
      amountSelection: false,
      donation: 0,
      boxContent: [],
      mainContent: '',
      formContent: '',
      formActionUrl: '',
      form: {
        donation: '',
        paymenttype: '',
        foa: '',
        company: '',
        birthday: '',
        firstname: '',
        lastname: '',
        email: '',
        street: '',
        postalcode: '',
        city: '',
        country: '',
        newsletter_common: '',
        data_privacy_consent: '',
      },
      errors: {}, // Für clientseitige Fehler
      serverErrors: [], // Für serverseitige Fehler
      dev: false,
      showForm: true,
      stripeInstance: stripe,
      paymentIntent: false,
      test: true,
      rewards: 0,
      donationAmount: 0,
      forceReRender: 0,
      paymentIntentData: false,
      stepsMap: {
        1: 'Dateneingabe',
        2: 'Zahlung',
        3: 'Bestätigung'
      },
      appearance: {
        theme: 'stripe',
        variables: {
          colorPrimary: '#d9b666',
          colorBackground: '#ffffff',
          colorText: '#1e1e1d',
          colorWarning: '#b50101',
          colorSuccess: '#098923',
          fontFamily: 'Overpass,sans-serif',
          fontWeight: '600',
          spacingUnit: '6px',
          borderRadius: '0',
          fontSizeBase: '1.3rem',
          fontSizeSm: '1rem',
        }
      },
      clientSecret: false,
      elements: false,
      paymentElementOptions: {
        layout: "tabs",
      },
      paymentReturnObject: false,
      customerReturnData: false,
      messages: [],
      viewport: window.innerWidth,
      isLoading: false,
      googlePayAvailability: false,
      applePayAvailability: false,
    }
  },
  mounted() {
    window.addEventListener('resize', function (event) {
      this.viewport = window.innerWidth;
    }, true);

    ['donationsRewardsJson', 'donationsMainJson'].forEach(key => {
      if (Object.hasOwn(window.moduleStripe, key)) {
        this[`${key === 'donationsRewardsJson' ? 'boxContent' : 'mainContent'}`] = window.moduleStripe[key];
      }
    });

    if (Object.hasOwn(window.moduleStripe, 'donationsHeadline')) {
      this.donationsHeadline = window.moduleStripe.donationsHeadline;
    }

    this['formContent'] = window.x2_form_vars;
    this['formActionUrl'] = window.x2_form_action_url;

    window.onresize = () => {
      this.viewport = window.innerWidth;
    }

    if (this.paymentReturnObject !== false) {
      this.scrollTo('message');
    }

    this.checkGooglePayAvailability();
    this.checkApplePayAvailability();
  },
  computed: {
    step() {
      if ((!this.paymentIntent || !this.showForm) && !this.paymentReturnObject) {
        return 1
      }
      else if(!this.paymentReturnObject || this.paymentReturnObject.redirect_status === 'failed') {
        return 2
      }
      else {
        return 3
      }
    },
    selectedReward() {
      if (!this.rewards) {
        return false
      }
      return this.boxContent.find(reward => parseInt(reward.id) === parseInt(this.rewards))
    },
    sliderCollumns() {
      let cols = 4;

      if (this.viewport < 700) {
        cols = 1
      } else if (this.viewport < 1200) {
        cols = 2
      } else if (this.viewport < 1500) {
        cols = 3
      }

      return cols;
    },
  },
  created() {
    // switch between payment and success page
    if (paymentReturn) {
      this.resolvePaymentReturn()
    }
    if (customerReturn) {
      // what was i going to do?
    }
  },
  methods: {
    async checkApplePayAvailability() {
      const paymentRequest = stripe.paymentRequest({
        country: "AT",
        currency: "eur",
        total: { label: "CheckApplePayAvailability", amount: 1 },
        requestPayerName: true,
        requestPayerEmail: true,
        supportedPaymentMethods: [
          {
            type: "apple_pay",
          },
        ],
      });

      const result = await paymentRequest.canMakePayment();

      this.applePayAvailability = result?.applePay ?? false;
    },
    async checkGooglePayAvailability() {
      const paymentRequest = stripe.paymentRequest({
        country: "AT",
        currency: "eur",
        total: { label: "CheckGooglePayAvailability", amount: 1 },
        requestPayerName: true,
        requestPayerEmail: true,
        supportedPaymentMethods: [
          {
            type: "google_pay",
          },
        ],
      });

      const result = await paymentRequest.canMakePayment();

      this.googlePayAvailability = result?.googlePay ?? false;
    },
   insertSavedData(data) {
     //prefilling the form
     this.paymentIntent = data.payment_intent;
     this.form.donation = data.Donation;
     this.form.paymenttype = data.PaymentType;
     this.form.foa = data.Foa;
     this.form.firstname = data.Firstname;
     this.form.lastname = data.Lastname;
     this.form.company = data.Company;
     if (data.Birthday && data.Birthday !== '0000-00-00') {
       this.form.birthday = data.Birthday;
     }
     this.form.email = data.EMail;
     this.form.street = data.Street;
     this.form.postalcode = data.PostalCode;
     this.form.city = data.City;
     this.form.country = data.Country;
     this.form.newsletter_common = data.NewsletterCommon;
     this.form.data_privacy_consent = data.DataPrivacyConsent;
     this.rewards = data.FK_ID_ContentElement_Reward;
   },
    async getPIDataFromDB(pi) {
     return new Promise((resolve) => {
       // getting data from db
       const apiUrl =  window.moduleStripe.main_surl
           + "index.php?page=MDL_stripepayment.&action=getPaymentIntentData&id="
           + pi;

       this.isLoading = true;
       let dataPromise = fetch(apiUrl);

       dataPromise.then(response => {
         let jsonPromise = response.json();

         jsonPromise.then(data => {
           this.isLoading = false;
           this.insertSavedData(JSON.parse(data.data.db_data))
           resolve(JSON.parse(data.data.db_data))
         })
       })
     })
    },
    resolvePaymentReturn() {
      // accepting data from url params
      this.paymentReturnObject = {
        payment_intent: urlParams.get('payment_intent'),
        payment_intent_client_secret: urlParams.get('payment_intent_client_secret'),
        redirect_status: urlParams.get('redirect_status'),
      }
      // deleting parameters after accepting their data
      urlParams.delete('redirect_status');
      urlParams.delete('payment_intent');
      urlParams.delete('payment_intent_client_secret');
      urlParams.delete('payment'); // leaving this one in there for now

      // replacing the url with the same url without the deleted parameters
      const nextURL = new URL(window.location.origin + window.location.pathname);
      nextURL.search = urlParams;
      const nextTitle = this.donationsHeadline;
      const nextState = { additionalInformation: 'Zahlungsparameter entfernt' };

      // This will replace the entry in the browser's history, without reloading
      window.history.replaceState(nextState, nextTitle, nextURL);

      // saving status to db
      this.postPaymentStatus(this.paymentReturnObject.payment_intent, this.paymentReturnObject.redirect_status);

      // geting accurate data from DB
      this.getPIDataFromDB(this.paymentReturnObject.payment_intent);

      // adapting display
      if (this.paymentReturnObject.redirect_status === 'succeeded') {
        this.showMessage('Ihre Spende wurde erfolgreich abgeschlossen. Wir danken Ihnen von Herzen.', 'success', 9999999999)
      }
      else {
        this.paymentFailed();
      }
    },
    insertTestData() {
      for (let key in this.form) {
        if (this.form[key] === '') {
          if (key === 'email') {
            this.form[key] = 'voelker@q2e.at';
          }
          else if (key === 'postalcode') {
            this.form[key] = 1234;
          }
          else if (key === 'birthday') {
            this.form[key] = '01.01.1990';
          }
          else if (key === 'paymenttype') {
            this.form[key] = 'paypal';
          }
          else if (key === 'donation') {
            this.form[key] = 1;
          }
          else if (key === 'foa') {
            this.form[key] = 1;
          }
          else {
            this.form[key] = key;
          }
        }
      }
    },
    scrollTo(string, input) {
      if (typeof this.$refs[string] !== 'undefined') {
        console.log(this.$refs[string]);
        this.$nextTick(() => {
          const yOffset = -310;
          const y = this.$refs[string].getBoundingClientRect().top + window.scrollY + yOffset;

            window.scrollTo({top: y, behavior: 'smooth'});


          if (input) {
            //this.$refs[input]?.focus();
          }
        })
      }
    },
    updateDonationConditionally(rewardPrice) {
      this.form.donation = rewardPrice
      this.scrollTo('donation', 'donation');
    },
    hideMessage(id) {
      this.messages = this.messages.filter((message) => message.id !== id)
    },
    confirmMessage(id) {
      let message = this.messages.find(message => id === message.id);

      if (!message.callback) {
        this.hideMessage(id);
      }
      else {
        this[message.callback](id);
      }
    },
    showMessage(message, type, duration = 4000, callback) {
      let id = Date.now() + Math.random(),
          obj = {
            text: message,
            type: type,
            id: id,
            callback: callback || false
          }

      if (typeof duration === 'string') {
        obj.confirmationQuestion = duration;
      }

      this.messages.push(obj);

      if (typeof duration === 'number') {
        setTimeout(() => {
          this.hideMessage(id)
        }, duration);
      }

      this.scrollTo('message');
    },
    initializePayment() {
      this.isLoading = false;
      this.elements = this.stripeInstance.elements({clientSecret: this.clientSecret, appearance: this.appearance});

      const paymentElement = this.elements.create("payment", this.paymentElementOptions);
      paymentElement.mount("#payment-element");

      this.scrollTo('payment');
    },
    tryParseJSONObject (jsonString){
      try {
        var o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object",
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
          return o;
        }
      }
      catch (e) {
        return false
      }

      return false;
    },
    async submit() {
      // reset alerts
      this.messages = [];
      this.isLoading = true;
      let form = new FormData();

      // add invisible field data
      this.form.FK_ID_ContentEntry_Parish = this.mainContent.parish_id;
      this.form.FK_ID_ContentEntry_Project = this.mainContent.project_id;
      this.form.ProjectName = this.donationsHeadline;
      this.form.ParishName = this.mainContent.parish_name;

      if (this.selectedReward) {
        this.form.FK_ID_ContentElement_Reward = this.selectedReward.id;
        this.form.RewardName = this.selectedReward.title;
      }

      // TODO: does the payment intent need to stay the same between payment attempts?
      //  Or maybe the client secret?
      // if (this.paymentIntent) {
      //   this.form.paymentIntent = this.paymentIntent;
      // }

      form.append('formData', JSON.stringify(this.form));

      // eslint-disable-next-line no-undef
      $.ajax({
        url: this.formActionUrl + '&rewards=' + this.rewards,
        type: 'POST',
        dataType: 'text',
        cache: false,
        contentType: false,
        processData: false,
        data: form,
        success: (response) => {
          let answer = this.tryParseJSONObject(response);
          if (!answer || answer.error ){
            if (!answer) {
              this.showMessage('Ein Fehler ist aufgetreten, die Spende konnte nicht abgeschlossen werden.', 'error', 'OK');
            }
            else if (answer.error) {
              // Set (error) messages from server Baseform validation
              for (let key in this.form) {
                if (    typeof answer['data']['form_vars'][`x2_field_${key}_message`] !== 'undefined'
                    && answer['data']['form_vars'][`x2_field_${key}_message`].length > 0) {
                  this.errors[key] = answer['data']['form_vars'][`x2_field_${key}_message`];
                  this.scrollToErrors();
                }
              }

              // only show an error message if no other errors are visible
              if (this.errors.length === 0) {
                this.showMessage(answer.message, 'error', 'OK');
              }
            }
            this.isLoading = false;
            return false
          }

          this.clientSecret = answer.data.clientSecret;
          this.paymentIntent = answer.data.paymentIntent;
          this.paymentIntentData = answer.data;

          this.isLoading = false;

          this.initializePayment();
        },
        error: (error) => {
          this.showMessage(error.message, 'error', 'OK');
          this.showMessage('Ein Fehler ist aufgetreten, die Spende konnte nicht abgeschlossen werden.', 'error', 'OK');
          this.isLoading = false;
        }
      })
    },
    async checkFormData() {
      // Clientseitige Validierung
      this.errors = {}; // Zurücksetzen der Fehler
      for (let key in this.form) {
        if (this.form[key] === '') {
          // optional fields are excempt
          if (!['birthday', 'company', 'newsletter_common'].includes(key)) {
            const label = this.formContent[`x2_field_${key}_label`];
            this.errors[key] = `Das Feld "${label}" muss ausgefüllt werden.`;
          }
        }

        if (key === 'company') {
          // Company is required for companies
          if (this.form.foa === '3') {
            this.form.birthday = ''; // unsetting a birthday in case one was added before selecting company
                                    // to prevent a birthday from leading to validation errors when none is relevant/visible

            if (this.form.company.length === 0) {
              this.errors.company = 'Das Feld "Firma" muss ausgefüllt werden.';
            }
          }
        }

        if (key === 'birthday') {
          // birthday is only available for natural persons
          if (this.form.foa === '1'|| this.form.foa === '2') {
            this.form.company = ''; // unsetting a company in case one was added before selecting Mann or Frau
            if (this.form.birthday.length > 0) {
              // check if is a valid Birthday
              let pieces = this.form.birthday.split('.');
              let year = new Date;
              function validateYear(string)  {
                if (typeof string === 'undefined') {return false}
                return (string.length === 4 && 1915 < parseInt(string) <= (year.getFullYear() - 18))
              }

              function validateMonth(string)  {
                if (typeof string === 'undefined') {return false}
                return (string.length === 2 && 0 < parseInt(string) <= 31)
              }

              function validateDay(string)  {
                if (typeof string === 'undefined') {return false}
                return (string.length === 2 && 0 < parseInt(string) <= 12)
              }

              if  (!validateYear(pieces[2]) || !validateMonth(pieces[1]) || !validateDay(pieces[0])) {
                this.errors.birthday = `Bitte geben Sie Ihren Geburtstag ohne Leerzeichen im Format 'TT.MM.JJJJ' an, z.B.: '01.01.1990'.`;
              }
            }
          }
        }
      }

      if (this.errors.length > 0) {
        this.scrollToErrors();
        return; // Keine weitere Verarbeitung, wenn Fehler vorhanden sind
      }

      // Serverseitige Validierung
      try {
        this.submit();
      } catch (error) {
        console.error('Fehler bei der Formularüberprüfung:', error);
      }
    },
    scrollToErrors() {
      Object.keys(this.errors).forEach(key => {
        if (this.errors[key].length > 0) {
          this.scrollTo(key, key);
        }
      })
    },
    async handleSubmitToStripe(e) {
      this.isLoading = true;
      this.postPaymentStatus(this.paymentIntent, 'started');

      let url = new URL(window.location.href);
      // let url = new URL(this.mainContent['donation_url']);
      url.searchParams.set('payment', 'return');
      // reset alerts
      this.messages = [];


      // 2. send to pay
      const {error} = await stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: this.mainContent.donation_url + '?payment=return',
        },
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        this.paymentFailed(error.message);
        this.postPaymentStatus(this.paymentIntent, 'valFail');
      } else {
        this.paymentFailed()
      }

      this.isLoading = false;
    },
    paymentFailed(message) {
      if (message) {
        this.showMessage(message, 'failure');
      }
      else {
        this.showMessage(
          'Die Zahlung war leider nicht erfolgreich. Ihre Zahlungsmethode wurde nicht belastet! Bitte kehren Sie zum Spendenformluar zurück um eine neue Spende zu starten.',
          'failure',
          'OK',
            'returnToPaymentPage'
        );
      }
    },
    postPaymentStatus(id = this.paymentIntent, status = 'test') {
      // 1. save payment intent to the db
      const apiUrl =  window.moduleStripe.main_surl + "index.php?page=MDL_stripepayment.&action=updatePaymentStatus&id=" + id + '&status=' + status;
      fetch(apiUrl);
    },
    async returnToPaymentPage(messageId = false) {
      let urlParams = new URLSearchParams();
      if (!this.paymentReturnObject) {
        urlParams.set('customer_return', this.paymentIntent);
      }
      else {
        urlParams.set('customer_return', this.paymentReturnObject.payment_intent);
      }

      // replacing the url with the same url with the additional parameter
      const nextURL = new URL(window.location.origin + window.location.pathname);
      nextURL.search = urlParams;
      const nextTitle = this.donationsHeadline;
      const nextState = { additionalInformation: 'Rückkehr zur Spende' };

      // This will replace the entry in the browser's history, without reloading
      if(messageId) {
        this.hideMessage(messageId)
      }
      window.history.replaceState(nextState, nextTitle, nextURL);
      this.paymentReturnObject = false;

      this.scrollTo('form');
    }
  },
  watch: {
    selectedReward(newValue) {
      if (newValue) {
        this.form.donation = newValue.price
      }
    }
  }
}
</script>

<template>
  <div :class="{'c-block': true, 'c-block--is-blocked': isLoading}">
  <div class="c-slider-teaser c-slider-teaser--rewards" v-if="boxContent.length > 0">
    <div class="c-slider-teaser__container">
      <div class="c-slider-teaser__main" v-if="mainContent.headline || mainContent.sub_headline">
        <h2 class="c-headline c-headline--gold js-cx_set_h1_if_first_position">
          <span class="c-headline__big headline--1" v-html="mainContent.headline"/>
          <span class="c-headline__sub" v-html="mainContent.sub_headline"/>
        </h2>
      </div>
      <div class="c-slider-teaser__swiper-container">
        <swiper
            :modules="modules"
            :slides-per-view="sliderCollumns"
            :space-between="50"
            :navigation="{nextEl: '#js-next', prevEl: '#js-prev'}"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            :pagination="false"
            :observe-parents="true"
            :observer="true"
        >
          <swiper-slide
              v-for="box in boxContent"
              :key="'reward' + box.id"
          >
            <div>
              <div class="c-slider-teaser__box">
                <div class="c-slider-teaser__box-img-container" v-if="box.image">
                  <div class="c-slider-teaser__box-img-price" v-html="'€ ' + box.price"></div>

                  <picture>
                    <source :srcset="box.image_webp"
                            type="image/webp">
                    <img :src="box.image"
                         :alt="box.image_alt"
                         :width="box.image_width"
                         :height="box.image_height"
                         class="c-slider-teaser__box-img"
                    >
                  </picture>
                  <div class="c-img-caption c-img-caption--over" v-if="box.image_title.length > 0"
                       v-html="box.image_title"/>
                </div>

                <div class="c-slider-teaser__box-content">
                  <h2 class="headline--3 c-slider-teaser__box-headline js-set_news_headline_same_height" v-html="box.title"/>
                  <div class="c-slider-teaser__box-text js-set_news_text_same_height" v-html="box.text"/>
                </div>

                <div v-if="box.sold_units < box.max_units">
                  <div style="overflow: hidden; position: relative">
                    <input v-model="rewards" type="radio" :id="'select_reward' + box.id" :value="box.id"
                           class="sr-only" style="position:absolute; left:-500px;" :disabled="paymentIntentData === false ? false : true">
                    <label
                        class="form__label u-no-before"
                        :for="'select_reward' + box.id"
                        @click="updateDonationConditionally(box.price)"
                    ><span class="c-slider-teaser__box-more">Spenden Sie jetzt <span v-html="box.price"/> €</span>
                    </label>
                  </div>
                  <div style="margin-top: 15px;">
                    <strong>Verfügbar: </strong>{{Math.max(0 , box.max_units - box.sold_units)}} von {{box.max_units}}
                  </div>
                </div>


              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div id="js-prev"
             class="c-slider-teaser__swiper-btn-container c-slider-teaser__swiper-btn-container--prev js-swiper_button_prev swiper-button-disabled"
             tabindex="-1" role="button" aria-label="Voriger Slide" aria-controls="swiper-wrapper-cd119a6cb9b10839d"
             aria-disabled="true">
          <img :src="mainContent.theme_url + 'build/svg/arrow-prev.svg'"
               alt="" class="c-slider-teaser__swiper-btn c-slider-teaser__swiper-btn--white">
          <img
              :src="mainContent.theme_url + 'build/svg/arrow-prev-blue.svg'"
              alt="" class="c-slider-teaser__swiper-btn c-slider-teaser__swiper-btn--blue">
        </div>
        <div id="js-next"
             class="c-slider-teaser__swiper-btn-container c-slider-teaser__swiper-btn-container--next js-swiper_button_next"
             tabindex="0" role="button" aria-label="Nächster Slide" aria-controls="swiper-wrapper-cd119a6cb9b10839d"
             aria-disabled="false">
          <img :src="mainContent.theme_url + 'build/svg/arrow-next.svg'"
               alt="" class="c-slider-teaser__swiper-btn c-slider-teaser__swiper-btn--white">
          <img
              :src="mainContent.theme_url + 'build/svg/arrow-next-blue.svg'"
              alt="" class="c-slider-teaser__swiper-btn c-slider-teaser__swiper-btn--blue">
        </div>
      </div>

    </div>
  </div><!-- ./Rewards -->

    <div class="container container-donation-form" ref="form"  id="form">
      <div class="row">
        <div class="col-lg-6">
          <h2 class="headline--1">Ihre Spende</h2>
        </div>
        <div class="col-lg-6">
          Schritt {{step}}/3<br>
          <ul class="steps-nav">
            <li v-for="(value, key) in stepsMap"
                :class="{
                  'steps-nav__item': true,
                  'steps-nav__item--past': step >= key,
                  'steps-nav__item--active': step == key
                }"
            >
              {{value}}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-if="step === 1" class="container container-donation-form">
    <!-- Spendensumme -->

    <h3 class="headline--3">Spende und Rewards</h3>
    <div class="row">
      <div class="col-md-6 col-12">
        <p>
          <input ref="rewards" type="radio" :value="0" v-model="rewards" id="free-donation" class="sr-only">
          <label for="free-donation">Freier Spendenbetrag</label>
        </p>
        <template v-for="box in boxContent"
                  :key="'rewardRadio' + box.id">
          <p v-if="box.sold_units < box.max_units">
            <input
                type="radio" :value="box.id" v-model="rewards" :id="'donation' + box.id" :disabled="box.sold_units < box.max_units ? false : true" class="sr-only">
            <label :for="'donation' + box.id"><span v-html="box.title" /> (Spendenbetrag fix {{box.price}} €)</label>
          </p>

        </template>
      </div>
      <div class="col-md-6 col-12">
        <label class="form__label" for="donation">Spendensumme</label>
        <div class="row">
          <div class="col-10">
            <input
                placeholder="10"
                ref="donation"
                id="donation"
                type="number"
                v-model="form.donation"
                :class="{ 'input-error': errors.donation }"
                style="text-align: right; margin-bottom: 0"
                min="1"
                :disabled="rewards === 0 ? false : true"
            />
          </div>
            <div class="col-2" style="display: flex; align-items: center">
            €
          </div>
        </div>

      </div>

    </div>
    <div class="row">
      <div class="col-sm-6" />
      <div class="col-sm-6">
        <small v-if="errors.donation" class="error-message" v-html="errors.donation" />
      </div>
    </div>

    <div>
      <h3 class="headline--3">{{ formContent.x2_field_paymenttype_label }}</h3>
      <div class="row" ref="paymenttype">
        <template v-for="paymentType in paymentTypes">
          <div class="col-auto" v-if="   (paymentType !== 'apple_pay' && paymentType !== 'google_pay')
                                      || (paymentType === 'apple_pay' && applePayAvailability)
                                      || (paymentType === 'google_pay' && googlePayAvailability)" style="display: flex; flex-direction: column; justify-content: center">
            <input  type="radio" :id="'paymentType' + paymentType" v-model="form.paymenttype" :value="paymentType" class="sr-only">
            <label :for="'paymentType' + paymentType" class="u-center">
              <span class="payment__img">
                <img v-if="paymentType !== 'card'" :src="mainContent.theme_url + 'build/svg/payment/' + paymentType + '.svg'" :alt="'Logo: Icon ' + paymentType">
                <span v-else>Kreditkarte</span>
              </span>
            </label>
        </div>
        </template>
      </div>

      <small v-if="errors.paymenttype" class="error-message" v-html="errors.paymenttype" />

    </div>

    <h3 class="headline--3">Persönliche Daten</h3>
    <form @submit.prevent="checkFormData()">
      <div class="row">
        <!-- Anrede -->
        <div class="col-sm-6">
          <label class="form__label" for="x2_field_foa">{{ formContent.x2_field_foa_label }}</label>
          <select
              ref="foa"
              id="x2_field_foa"
              v-model="form.foa"
              :class="{ 'input-error': errors.foa }"
          >
            <option value="1">
              Frau
            </option>
            <option value="2">
              Herr
            </option>
            <option value="3">
              Firma
            </option>
          </select>
          <small v-if="errors.foa" class="error-message" v-html="errors.foa" />
        </div>
        <div class="col-sm-6" v-if="form.foa === '3'">
          <label class="form__label" for="company">{{ formContent.x2_field_company_label }}</label>
          <input ref="company" v-model="form.company" id="company" placeholder="Q2E GmbH" type="text" :class="{ 'input-error': errors.company }">
          <small v-if="errors.company" class="error-message" v-html="errors.company" />
        </div>
        <!-- Geburtstag -->
        <div v-else class="col-sm-6">
          <label class="form__label" for="x2_field_birthday">{{ formContent.x2_field_birthday_label }}</label>
          <input
              id="x2_field_birthday"
              ref="birthday"
              placeholder="24.12.1990"
              type="text"
              v-model="form.birthday"
              :class="{ 'input-error': errors.birthday }"
          />
          <small v-if="errors.birthday" class="error-message">{{ errors.birthday }}</small>
        </div>
      </div>
      <div class="row">
        <!-- Vorname -->
        <div class="col-sm-6">
          <label class="form__label" for="x2_field_firstname">{{ formContent.x2_field_firstname_label }}</label>
          <input
              ref="firstname"
              id="x2_field_firstname"
              placeholder="Maxima"
              type="text"
              v-model="form.firstname"
              :class="{ 'input-error': errors.firstname }"
          />
          <small v-if="errors.firstname" class="error-message" v-html="errors.firstname" />
        </div>
        <!-- Nachname -->
        <div class="col-sm-6">
          <label class="form__label" for="x2_field_lastname">{{ formContent.x2_field_lastname_label }}</label>
          <input
              ref="lastname"
              id="x2_field_lastname"
              placeholder="Musterfrau"
              type="text"
              v-model="form.lastname"
              :class="{ 'input-error': errors.lastname }"
          />
          <small v-if="errors.lastname" class="error-message" v-html="errors.lastname" />
        </div>
      </div>

      <h3 class="headline--3">Kontaktdaten</h3>
      <div class="row">
        <!-- E-Mail -->
        <div class="col-sm-6">
          <label class="form__label" for="x2_field_email">{{ formContent.x2_field_email_label }}</label>
          <input
              id="x2_field_email"
              ref="email"
              placeholder="kontakt@lilienfeld.at"
              type="text"
              v-model="form.email"
              :class="{ 'input-error': errors.email }"
          />
          <small v-if="errors.email" class="error-message" v-html="errors.email" />
        </div>

        <!-- Straße -->
        <div class="col-sm-6">
          <label class="form__label" for="x2_field_street">{{ formContent.x2_field_street_label }}</label>
          <input
              ref="street"
              id="x2_field_street"
              placeholder="Kirchenweg 1"
              type="text"
              v-model="form.street"
              :class="{ 'input-error': errors.street }"
          />
          <small v-if="errors.street" class="error-message" v-html="errors.street" />
        </div>
        <!-- PLZ -->
        <div class="col-sm-6">
          <label class="form__label" for="x2_field_postalcode">{{ formContent.x2_field_postalcode_label }}</label>
          <input
              ref="postalcode"
              id="x2_field_postalcode"
              placeholder="1234"
              type="text"
              v-model="form.postalcode"
              :class="{ 'input-error': errors.postalcode }"
          />
          <small v-if="errors.postalcode" class="error-message" v-html="errors.postalcode" />
        </div>
        <!-- Ort -->
        <div class="col-sm-6">
          <label class="form__label" for="x2_field_city">{{ formContent.x2_field_city_label }}</label>
          <input
              ref="city"
              id="x2_field_city"
              placeholder="Lilienfeld"
              type="text"
              v-model="form.city"
              :class="{ 'input-error': errors.city }"
          />
          <small v-if="errors.city" class="error-message" v-html="errors.city" />
        </div>
        <!-- Land -->
        <div class="col-sm-12">
          <label class="form__label" for="x2_field_country">{{ formContent.x2_field_country_label }}</label>
          <select
              ref="country"
              id="x2_field_country"
              v-model="form.country"
              :class="{ 'input-error': errors.country }"
          >
            <option value="1">
              Österreich
            </option>
            <option value="2">
              Deutschland
            </option>
          </select>
          <small v-if="errors.country" class="error-message" v-html="errors.country" />
        </div>
        <div class="col-sm-12">
          <h3 class="headline--3">Zustimmung zur Datenverarbeitung</h3>
          <p>
            <input type="checkbox" v-model="form.newsletter_common" value="1" name="x2_field_field[x2_field_newsletter_common]" id="x2_field_field[x2_field_newsletter_common]" class="sr-only"><!--
            --><label for="x2_field_field[x2_field_newsletter_common]">
              {{ formContent.x2_field_newsletter_common_label }}
            </label>
            <small v-if="errors.newsletter_common" class="error-message" v-html="errors.newsletter_common" />
          </p>
          <p>
            <input type="checkbox" v-model="form.data_privacy_consent" value="1" name="x2_field_field[x2_field_data_privacy_consent]" id="x2_field_field[x2_field_data_privacy_consent]" class="sr-only"><!--
            --><label for="x2_field_field[x2_field_data_privacy_consent]">
            {{ formContent.x2_field_data_privacy_consent_label }}
          </label>
            <small v-if="errors.data_privacy_consent" class="error-message" v-html="errors.data_privacy_consent" />
          </p>
<!--          <p>-->
<!--            <input type="checkbox" v-model="form.newsletter_parish" value="1" name="x2_field_field[x2_field_newsletter_parish]" id="x2_field_field[x2_field_newsletter_parish]" class="sr-only">&lt;!&ndash;-->
<!--            &ndash;&gt;<label for="x2_field_field[x2_field_newsletter_parish]">-->
<!--              {{ formContent.x2_field_newsletter_parish_label }}-->
<!--            </label>-->
<!--            <small v-if="errors.newsletter_parish" class="error-message" v-html="errors.newsletter_parish" />-->
<!--          </p>-->
<!--          <p>-->
<!--            <input type="checkbox" v-model="form.newsletter_project" value="1" name="x2_field_field[x2_field_newsletter_project]" id="x2_field_field[x2_field_newsletter_project]" class="sr-only">&lt;!&ndash;-->
<!--            &ndash;&gt;<label for="x2_field_field[x2_field_newsletter_project]">-->
<!--              {{ formContent.x2_field_newsletter_project_label }}-->
<!--            </label>-->
<!--            <small v-if="errors.newsletter_project" class="error-message" v-html="errors.newsletter_project" />-->
<!--          </p>-->
        </div>
      </div>

      <payment-stripe-selected-reward-display
          :donations-headline="donationsHeadline"
          :donations-image="{image: mainContent.image, image_alt: mainContent.image_alt}"
          :donation="form.donation"
          :selected-reward="selectedReward"
      >
        <button @click.prevent="insertTestData()" v-if="dev">Testdaten einfügen</button>
        <button @click="checkFormData($event)" class="c-btn" :disabled="isLoading">Weiter</button>
      </payment-stripe-selected-reward-display>
    </form>
  </div>

  <div class="container" ref="message">
    <payment-stripe-selected-reward-display
        v-if="paymentReturnObject"
        :donations-headline="donationsHeadline"
        :donations-image="{image: mainContent.image, image_alt: mainContent.image_alt}"
        :donation="form.donation"
        :selected-reward="selectedReward"
    >
      <div :class="'c-messages c-messages--' + message.type" v-for="message in messages" :key="message.id">
        <div v-html="message.text"/>
        <button v-if="typeof message.confirmationQuestion !== 'undefined'" @click="confirmMessage(message.id)">
          {{ message.confirmationQuestion }}
        </button>
      </div>
    </payment-stripe-selected-reward-display>
    <template v-else>
      <div :class="'c-messages c-messages--' + message.type" v-for="message in messages" :key="message.id">
        <div v-html="message.text"/>
        <button v-if="typeof message.confirmationQuestion !== 'undefined'" @click="confirmMessage(message.id)">
          {{ message.confirmationQuestion }}
        </button>
      </div>
    </template>
  </div>

  <div class="container" v-show="step === 2"><!-- v-show because otherwise the payment-element doesn't exist before init starts -->
    <form ref="payment" id="payment-form" :key="forceReRender"><!-- forceReRender on every try -->
      <div id="card-element">
      </div>
      <div id="payment-element">
        <!--Stripe.js injects the Payment Element-->
      </div>

      <payment-stripe-selected-reward-display
          v-if="paymentIntentData"
          :donations-headline="donationsHeadline"
          :donations-image="{image: mainContent.image, image_alt: mainContent.image_alt}"
          :donation="paymentIntentData.formData.donation"
          :selected-reward="selectedReward"
      >
        <button @click.prevent="handleSubmitToStripe($event)" class="c-btn" v-if="paymentIntent">jetzt spenden</button>
      </payment-stripe-selected-reward-display>

    </form>
  </div>



  <div class="c-block__spinner"></div>
</div>
</template>

<style lang="scss" scoped>
.container-donation-form {
  margin-top: 50px;
  margin-bottom: 50px;
}

.input-error {
  border-color: rgb(223, 27, 65) !important;
  background-color: #ffe6e6;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.error-message {
  color: rgb(223, 27, 65);
  font-size: 20.8px;
  line-height: 23.92px;
  margin-bottom: 0.25rem;
  display: block;
}


@keyframes rotateSpinner {
  to {
    transform: rotate(360deg);
  }
}

.col-auto {
  flex: 1 1 auto;
}

.c-block {
  position: relative;

  &__spinner {
    display: none;
  }

  &.c-block--is-blocked {

    * {
      pointer-events: none;
      opacity: 0.8;
    }

    .c-block__spinner {
      opacity: 1;
      color: #f2f6fa;
      display: block;
      font: normal normal normal 4rem/1 FontAwesome;
      top: 50%;
      left: 50%;
      position: fixed;
      animation: rotateSpinner 1600ms linear infinite;

      &:before {
        content: '\f110';
        display: block;
      }
    }
  }
}

input:checked+label {
  .payment__img{
    border: 2px solid #d9b666;
  }
}

.payment {
  &__img {
    margin-top: 10px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 15px;
    min-height: 72px;
    cursor: pointer;

    img {
      background-color: #fff !important;
      vertical-align: middle;
      line-height: 0;
      height: 23px;
      width: auto;
    }
  }
}

h3 {
  margin-top: 20px;
  margin-bottom: 14px;
}

.steps-nav {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__item {
    position: relative;
    padding-left: 0;
    transition: padding-left 0.3s;

    flex-grow: 1;
    color: #1e1e1d;
    border: none;
    text-decoration: none;
    font-weight: 700;
    text-transform: uppercase;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      display: inline-block;
      height: 3px;
      background-color: transparent;
      width: 0;
      transition: width 0.3s;
      top: 15px;
    }

    &--past {
      color: #d9b666;
    }

    &--active {
      padding-left: 35px;

      &:before {
        width: 30px;
        background-color: #d9b666;
      }
    }
  }
}

.contrast-is-active {
  .steps-nav {
    &__item {
      &--past {
        color: #68665F;
      }

      &--active {
        &:before {
          background-color: #68665F;
        }
      }
    }
  }
}
</style>
