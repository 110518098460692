<template>
    <div class="row" style="margin-top: 40px; margin-bottom: 30px;">
        <div class="col-sm-4">
          <img
              :src="selectedReward ? selectedReward.image : donationsImage.image"
              :alt="selectedReward ? selectedReward.image_alt : donationsImage.image_alt"
          />
        </div>
        <div class="col-sm-6">
          <h4 class="c-headline c-headline--gold">
            <span class="c-headline__big" v-html="donationsHeadline" />
            <span class="c-headline__sub">
            <template v-if="donation">
              Ihre Spende: {{ formatPrice(donation) }}
            </template>
            <template v-else>
              Sie haben keine Spendensumme angegeben.
            </template>
          </span>
          </h4>
          <p v-if="selectedReward">
            Sie haben das Geschenk »<span v-html="selectedReward.title"/>« als Reward gewählt.
          </p>
          <template v-else-if="donation">
            <p>Sie haben keinen Reward gewählt.</p>
            <p>Herzliches Vergelts Gott!</p>
          </template>

          <slot />
        </div>
      </div>
</template>
<script>
export default {
  name: 'PaymentStripeSelectedRewardDisplay',
  props: {
    donationsHeadline: {
      type: String,
      required: true,
    },
    donationsImage: {
      type: [Object, String],
      default: '',
    },
    donation: {
      type: Number,
      required: true
    },
    selectedReward: {
      type: [Object, Boolean],
      default: false
    }
  },
  methods: {
    formatPrice(price) {
      let euro = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });

      return euro.format(price)
    }
  }
}
</script>
